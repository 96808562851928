export const dataExperencie = [
  {
    id: 0,
    title: "Ruta99",
    text: "Ruta99 is a software that helps you make your delivery routes more efficient. You can plan, monitor, and analyze your deliveries all in one place.",
    imgs: [0, 1, 2, 3],
  },
  {
    id: 1,
    title: "Tracking Ruta99",
    text: "With Tracking Ruta99, the end customer will be able to perform a detailed tracking of their order, not only visualizing the different states of it but also the date, time, and actions carried out by the delivery driver in charge.",
    imgs: [0, 1, 2],
  },
  {
    id: 2,
    title: "Tailor Made",
    text: "Tailor Made offers personalized logistics solutions for e-commerce companies, providing vehicles and drivers for B2C deliveries, enabling customers to optimize routes and track their orders in detail.",
    imgs: [0, 1, 2],
  },

  {
    id: 3,
    title: "Scheduling & Planning",
    text: "Tailor Made - Scheduling & Planning is software designed to manage suppliers and optimize the last mile in 99minutos. The proposal focuses on a system for planning and scheduling, aiming to achieve a fully automated operation.",
    imgs: [0, 1, 2, 3],
  },

  {
    id: 4,
    title: "Route Monitor",
    text: "Route Monitor is software designed to track pickups and deliveries accurately. It offers diagrams to visualize progress, a table to filter and group routes, and complete route details with a map and coordinates.",
    imgs: [0, 1, 2],
  },
];
