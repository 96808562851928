import space1 from "./img/space1.png";
import space2 from "./img/space2.png";
import space3 from "./img/space3.png";

import movie1 from "./img/movie1.PNG";
import movie2 from "./img/movie2.PNG";
import movie3 from "./img/movie3.PNG";

import drink1 from "./img/drink1.png";
import drink2 from "./img/drink2.png";
import drink3 from "./img/drink3.png";

import poke1 from "./img/poke1.png";
import poke2 from "./img/poke2.png";
import poke3 from "./img/poke3.png";
import poke4 from "./img/poke4.png";

export const data = [
  {
    id: 0,
    title: "SpaceX",
    description:
      "It is a SPA that consumes data to the https://api.spacex.land/graphql/ API using the Apollo client. Also using ReactJS, Sass and Bootstrap.",
    url: "https://github.com/davidheredia25/99minutos-frontend-interview-test/tree/JR-davidheredia",
    img: [
      {
        name: space2,
        alt: "First slide",
      },
      {
        name: space1,
        alt: "Second slide",
      },
      {
        name: space3,
        alt: "Third slide",
      },
    ],
  },

  {
    id: 1,
    title: "E-shop drinks",
    description:
      "It is a group project, which deals with an E-Commerce on drinks, using SCRUM as an agile methodology, and different technologies such as: ReactJS, Redux, CSS, Bootstrap, Node.js, Express, MongoDB and Mongoose.",
    url: "https://github.com/davidheredia25/Drinks-App",
    img: [
      {
        name: drink3,
        alt: "First slide",
      },
      {
        name: drink1,
        alt: "Second slide",
      },
      {
        name: drink2,
        alt: "Third slide",
      },
    ],
  },

  {
    id: 2,
    title: "Poke-Api",
    description:
      "It is about a SPA that consumes data from an API (https://pokeapi.co) The technologies used for #Backend, #Nodejs, #ExpressJS and #Sequelize as #ORM. In the #PostgreSQL and #Frontend databases, it is developed in #React, #Redux and pure #Css.",
    url: "https://github.com/davidheredia25/PI-Pokemon-FT17a",
    img: [
      {
        name: poke1,
        alt: "First slide",
      },
      {
        name: poke2,
        alt: "Second slide",
      },
      {
        name: poke3,
        alt: "Third slide",
      },
      {
        name: poke4,
        alt: "Third slide",
      },
    ],
  },
  {
    id: 3,
    title: "Movies-api",
    description:
      "Work in Movies Api, using React-Redux. You can search for movies, add them to favorites and see all the details of them.",
    url: "https://github.com/davidheredia25/Movies-app",
    img: [
      {
        name: movie1,
        alt: "First slide",
      },
      {
        name: movie2,
        alt: "Second slide",
      },
      {
        name: movie3,
        alt: "Third slide",
      },
    ],
  },
];
