import React from "react";
import Modal from "react-bootstrap/Modal";
import { Carousel } from "react-bootstrap";

const ModalSeeMore = ({ openModal, setOpenModal, slideIndexData }) => {
  return (
    <Modal
      show={openModal}
      size="xl"
      centered
      onHide={() => setOpenModal(false)}
    >
      <Carousel fade variant="dark">
        {slideIndexData &&
          slideIndexData.imgs &&
          slideIndexData.imgs.map((img) => (
            <Carousel.Item key={img}>
              <img
                className="d-block w-100"
                src={`./${slideIndexData.title}-${img}.png`}
                alt={`./${slideIndexData.title}-${img}.png`}
              />
            </Carousel.Item>
          ))}
      </Carousel>
    </Modal>
  );
};
export default ModalSeeMore;
