import React, { useState, useEffect } from "react";
import Contact from "../Contact/Contact";
import Home from "../Home/Home";
import Proyects from "../Proyects/Proyects/Proyects";
import Skills from "../Skills/Skills";
import Footer from "../Footer/Footer";
import Experencie from "../Experencie/Experencie";

const Render = () => {
  const [dimensions, setDimensions] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setDimensions(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div>
      <Home dimensions={dimensions} />
      <Experencie dimensions={dimensions} />
      <Proyects dimensions={dimensions} />
      <Skills />
      <Contact />
      <Footer />
    </div>
  );
};

export default Render;
