import React from "react";
import style from "./Home.module.scss";
import NavBar from "../NavBar/NavBar.jsx";
import { BsArrowDownSquare } from "react-icons/bs";
import { Link } from "react-scroll";
import { motion } from "framer-motion";

const Home = ({ dimensions }) => {
  return (
    <section id="home">
      <div className={style.ctnS}>
        <NavBar dimensions={dimensions} />
        <div className={style.ctnT}>
          <motion.div
            initial={{ x: dimensions >= 500 ? 200 : 0 }}
            animate={{ x: 0 }}
            transition={{ duration: 2 }}
          >
            <h1 className={style.name}> Hi! I am</h1>

            <h1 className={style.name}> David Heredia</h1>
            <p className={style.txt}>
              A Full Stack Developer who builds websites.
            </p>
          </motion.div>
        </div>
        <Link to="experience" smooth={true} duration={1000}>
          <BsArrowDownSquare className={style.icon} />
        </Link>
      </div>
    </section>
  );
};

export default Home;
