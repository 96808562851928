import React, { useState } from "react";
import style from "./Experencie.module.scss";
import monitor from "./img/monitor.jpg";
import { Tab, Tabs, Box, IconButton } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useEffect } from "react";
import { dataExperencie } from "./dataExperiencie";
import { RiArrowLeftLine, RiArrowRightLine } from "react-icons/ri";
import { motion } from "framer-motion";
import ModalSeeMore from "./ModalSeeMore";

const StyledTab = styled(Tab)({
  "&.Mui-selected": {
    color: "white",
    fontFamily: "Montserrat",
    textTransform: "none",
  },
});

const Experencie = ({ dimensions }) => {
  const [slideIndexData, setSlideIndexData] = useState(dataExperencie[0]);
  const [slideIndex, setSlideIndex] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [key, setKey] = useState(0);

  const handleChange = (event, data) => {
    setSlideIndex(data.id);
  };

  const handleNext = () => {
    if (slideIndex === dataExperencie.length - 1) {
      setSlideIndex(0);
    } else {
      setSlideIndex(slideIndex + 1);
    }
  };

  const handlePrevious = () => {
    if (slideIndex === 0) {
      setSlideIndex(dataExperencie.length - 1);
    } else {
      setSlideIndex(slideIndex - 1);
    }
  };

  useEffect(() => {
    const selected = dataExperencie.find((data) => data.id === slideIndex);
    setSlideIndexData(selected);
    setKey((prevKey) => prevKey + 1);
  }, [slideIndex]);

  const handleOpenModal = () => {
    setOpenModal(!openModal);
  };

  return (
    <section className={style.ctn} id="experience">
      <div className={style.ctnTxt}>
        <h1 className={style.titleSup}>EXPERIENCE</h1>
        <p className={style.text}>
          Currently in 99minutos as Front End Developer.
        </p>
      </div>

      <motion.div
        initial={{ opacity: 0, scale: 0.5 }}
        whileInView={{ opacity: 1, scale: 1 }}
        transition={{ duration: 2.5 }}
        viewport={{ once: true }}
      >
        <div className={style.ctnOverlaySoport}>
          <div className={style.overlay}>
            <img className={style.monitor} src={monitor} alt={monitor} />

            <motion.div
              key={key}
              initial={{ opacity: 0.3 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 1 }}
            >
              <img
                onClick={handleOpenModal}
                key={slideIndexData.id}
                className={style.overlayImage}
                src={`./${slideIndexData.title}.png`}
                alt={`./${slideIndexData.title}.png`}
              />
            </motion.div>
          </div>
          <div className={style.ctnSoport}>
            <div className={style.soport}></div>
            <div className={style.soportRadiurs}></div>
          </div>
        </div>
      </motion.div>

      <div className={style.ctnBnts}>
        <Box
          sx={{
            borderBottom: 0.5,
            borderColor: "#b5b5b5",
            mt: -5,
            display: "flex",
            alignItems: "center",
          }}
        >
          {dimensions <= 700 && (
            <IconButton
              onClick={handlePrevious}
              sx={{ color: "white", p: 0, mr: 1, fontSize: 18 }}
            >
              <RiArrowLeftLine />
            </IconButton>
          )}

          <Tabs
            value={slideIndexData}
            onChange={handleChange}
            TabIndicatorProps={{
              style: {
                backgroundColor: "white",
                height: 1,
              },
            }}
          >
            {dataExperencie.map((data) => (
              <StyledTab
                value={data}
                sx={{
                  color: "#b5b5b5",
                  fontFamily: "Montserrat",
                  textTransform: "none",
                }}
                label={data.title}
                key={data.id}
              />
            ))}
          </Tabs>
          {dimensions <= 700 && (
            <IconButton
              onClick={handleNext}
              sx={{ color: "white", p: 0, ml: 1, fontSize: 18 }}
            >
              <RiArrowRightLine />
            </IconButton>
          )}
        </Box>
      </div>

      <div className={style.ctnTextAndBtn}>
        <box className={style.textDesciption}>
          <motion.div
            key={key}
            initial={{ opacity: 0.3 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5 }}
          >
            {slideIndexData && slideIndexData.text ? slideIndexData.text : "-"}
          </motion.div>
        </box>

        <button onClick={handleOpenModal} className={style.btnVerMas}>
          See more
        </button>
      </div>

      <ModalSeeMore
        openModal={openModal}
        setOpenModal={setOpenModal}
        slideIndexData={slideIndexData}
      />
    </section>
  );
};
export default Experencie;
