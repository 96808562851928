import React, { useState } from "react";
import style from "./DetailsProyect.module.scss";
import Carousel from "react-bootstrap/Carousel";
import Modal from "react-bootstrap/Modal";
import Paper from "@mui/material/Paper";

const DetailsProyect = ({ project }) => {
  const [show, setShow] = useState(false);

  return (
    <Paper variant="outlined" sx={{ borderRadius: 4, boxShadow: 0 }}>
      <div className={style.CtnPricipalMovie}>
        <img
          className={style.image1}
          src={project?.img[0].name}
          alt={project?.img[0].alt}
        />

        <div className={style.ctnTextoCaru}>
          <div>
            <p className={style.title}> {project?.title} </p>
            <p className={style.textoCaru1}>
              {project?.description}
              <a href={project?.url} target="_blank" rel="noreferrer">
                Code
              </a>
            </p>
          </div>
          <button className={style.btnSeeMore} onClick={() => setShow(true)}>
            See more
          </button>
        </div>

        <Modal show={show} size="xl" centered onHide={() => setShow(false)}>
          <Carousel fade variant={project.id === 1 && "dark"}>
            {project?.img?.map((e, k) => (
              <Carousel.Item key={k}>
                <img className="d-block w-100" src={e?.name} alt={e?.alt} />
              </Carousel.Item>
            ))}
          </Carousel>
        </Modal>
      </div>
    </Paper>
  );
};

export default DetailsProyect;
