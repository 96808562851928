import React, { useState, useEffect, useRef } from "react";
import Slider from "react-slick";
import style from "./Proyects.module.scss";
import DetailsProyect from "../DetailsProyect/DetailsProyect.jsx";
import { Tab, Tabs, Box, IconButton, Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import { data } from "../dataProyects";
import { RiArrowLeftLine, RiArrowRightLine } from "react-icons/ri";
import { motion } from "framer-motion";

const StyledTab = styled(Tab)({
  "&.Mui-selected": {
    color: "black",
    fontFamily: "Montserrat",
    textTransform: "none",
  },
});

const Proyects = ({ dimensions }) => {
  const [slideIndexData, setSlideIndexData] = useState();
  const [slideIndex, setSlideIndex] = useState(0);
  const [updateCount, setUpdateCount] = useState(0);
  let sliderRef = useRef(null);

  const handleClick = (event, data) => {
    setSlideIndex(data.id);
    sliderRef.slickGoTo(data.id);
  };

  const handleNext = () => {
    if (slideIndex === data.length - 1) {
      setSlideIndex(0);
      sliderRef.slickGoTo(0);
    } else {
      setSlideIndex(slideIndex + 1);
      sliderRef.slickGoTo(slideIndex + 1);
    }
  };

  const handlePrevious = () => {
    if (slideIndex === 0) {
      setSlideIndex(data.length - 1);
      sliderRef.slickGoTo(data.length - 1);
    } else {
      setSlideIndex(slideIndex - 1);
      sliderRef.slickGoTo(slideIndex - 1);
    }
  };

  const settings = {
    slidesToShow: 2,
    speed: 500,
    arrows: false,
    afterChange: () => setUpdateCount(updateCount + 1),
    beforeChange: (current, next) => setSlideIndex(next),
    responsive: [
      {
        breakpoint: 1500,
        settings: {
          className: "center",
          centerMode: true,
          slidesToShow: 1.1,
          initialSlide: 1.1,
          centerPadding: "200px",
        },
      },
      {
        breakpoint: 1200,
        settings: {
          className: "center",
          centerMode: true,
          slidesToShow: 1.1,
          centerPadding: "100px",
        },
      },

      {
        breakpoint: 600,
        settings: {
          className: "center",
          centerMode: true,
          slidesToShow: 1,
          centerPadding: "0px",
        },
      },
    ],
  };

  useEffect(() => {
    const selected = data.find((proyect) => proyect.id === slideIndex);
    setSlideIndexData(selected);
  }, [slideIndex]);

  return (
    <section className={style.ctnSec} id="projects">
      <div className={style.ctnTxt}>
        <h1 className={style.titleSup}>PROJECTS</h1>

        <p className={style.text}>
          Projects carried out during the bootcamp SoyHenry
        </p>
      </div>

      <div
        className={style.ctnBnts}
        style={{ display: dimensions > 450 && "flex" }}
      >
        <Box
          sx={{
            borderBottom: 0.5,
            borderColor: "#666666",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {dimensions <= 450 && (
            <IconButton
              onClick={handlePrevious}
              sx={{ color: "black", p: 0, mr: 0.5, fontSize: 18 }}
            >
              <RiArrowLeftLine />
            </IconButton>
          )}
          <Tabs
            value={slideIndexData}
            onChange={handleClick}
            TabIndicatorProps={{
              style: {
                backgroundColor: "black",
                height: 1,
              },
            }}
          >
            {data.map((data) => (
              <StyledTab
                value={data}
                sx={{
                  color: "#666666",
                  fontFamily: "Montserrat",
                  textTransform: "none",
                }}
                label={data.title}
                key={data.id}
              />
            ))}
          </Tabs>
          {dimensions <= 450 && (
            <IconButton
              onClick={handleNext}
              sx={{ color: "black", p: 0, ml: 0.5, fontSize: 18 }}
            >
              <RiArrowRightLine />
            </IconButton>
          )}
        </Box>
      </div>

      <motion.div
        initial={{ opacity: 0, scale: 0.5 }}
        whileInView={{ opacity: 1, scale: 1 }}
        transition={{ duration: 2 }}
        viewport={{ once: true }}
      >
        <Slider
          {...settings}
          ref={(slider) => {
            sliderRef = slider;
          }}
        >
          {data.map((project) => (
            <Grid container key={project.id} sx={{ cursor: "pointer" }}>
              <Grid item p={dimensions < 450 ? 2 : 3}>
                <DetailsProyect project={project} />
              </Grid>
            </Grid>
          ))}
        </Slider>
      </motion.div>
    </section>
  );
};

export default Proyects;
